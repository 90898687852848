* {
  padding: 1px;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  min-height: 100vh;
  background: #1c273a;
  position: absolute;
  text-align: center;
  color: white;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 4px;
  color: wheat;
  margin: 20px 0;
}

button {
  padding: 5px 20px;
  margin: 10px;
  cursor: pointer;
}

.loading img {
  margin: 10px;
  height: 50px;
}

body {
  padding: 0;
  margin: 0;
  background-color: #fafafa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
}
table { 
  width: 100%; 
  border-collapse: collapse; 
}
/* Zebra striping */
tr:nth-of-type(odd) { 
  background: #eee; 
}
th { 
  background: #333; 
  color: white; 
  font-weight: bold; 
}
td, th { 
  padding: 6px; 
  border: 1px solid #ccc; 
  text-align: left; 
}

.text-14-bold {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}

.text-14-light {
  font-size: 14px;
  line-height: 22px;
}

.text-12-bold {
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
}

.text-12-light {
  font-size: 12px;
  line-height: 14px;
  color: #8e8e8e;
}

.text-blue {
  color: #0095f6;
}

.text-red {
  color: #ed4956;
}

.container {
  display: contents;
}

.box {
  /* border: solid 1px #dbdbdb; */
  border-radius: 3px;
  /* background-color: #fff; */
}

.stories-container {
  width: 100%;
  height: 116px;
}

.stories-feed {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 4px;
  -webkit-overflow-scrolling: touch;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.story-photo-container {
  background: linear-gradient(45deg, #f37b12, #c62d90);
  border-radius: 100%;
  /* padding: 2px; */
}

.story-username {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80px;
  text-align: center;
}

.story-item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0 4px;
}

.stories-button {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.feed-body {
  display: flex;
  justify-content: center;
  padding: auto auto;
}

.feed-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feed-post {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.21fr 1.1fr 0.1fr;
  gap: 0px 0em;
  grid-template-areas: ".";
  justify-items: stretch;
  border: 1px solid white;
  width: 100vw;
}

.feed-post-header {
  border: 1px solid black;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 11px;
  gap: 1rem;
}

.feed-post-header h2 {
  text-align: left;
  overflow: hidden;
  max-height: 65px;
  text-overflow: ellipsis;
  white-space: normal;
}

.feed-post-photos {
  border: 1px solid black;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  align-items: center;
  justify-content: center;
}

/* Hide scrollbar in Chrome */
.feed-post-photos::-webkit-scrollbar {
  display: none;
}
.arrows{
  position: relative;
  top:-50%;
}
.photos {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 2px solid #ddd;
  border-radius: 5px;
  animation: scale 0.5s linear;
  display: inline-block;
  margin-right: 10px;
  flex: none;
}

@keyframes scale {
  from {
    transform: scale(0.5);
  }
}

.photos img,
.photos video {
  width: 100%;
  display: block;
  height: 100%;
    object-fit: cover;
  }

.photos p {
  text-transform: capitalize;
  letter-spacing: 2px;
}

.photos span {
  color: chocolate;
  margin: 0 10px;
}

.feed-post-description {
  border: 1px solid black;
  padding: 1px 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ant-modal-wrap{
  backdrop-filter: blur(4px) !important;
}

@media only screen and (max-width: 767px) {
  .feed-post {
    width: 100vw;
    height: 80vh;
  }

/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Product Type"; }
	td:nth-of-type(2):before { content: "Product Metal Type"; }
	td:nth-of-type(3):before { content: "Metal Rate"; }
	td:nth-of-type(4):before { content: "Product Weight"; }
	td:nth-of-type(5):before { content: "Product Labour"; }
	td:nth-of-type(6):before { content: "Product Extra Charge"; }
	td:nth-of-type(7):before { content: "Discount"; }
	td:nth-of-type(8):before { content: "Total"; }

}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .feed-post {
    max-width: 449px;
    height: 649px;
  }

/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Product Type"; }
	td:nth-of-type(2):before { content: "Product Metal Type"; }
	td:nth-of-type(3):before { content: "Metal Rate"; }
	td:nth-of-type(4):before { content: "Product Weight"; }
	td:nth-of-type(5):before { content: "Product Labour"; }
	td:nth-of-type(6):before { content: "Product Extra Charge"; }
	td:nth-of-type(7):before { content: "Discount"; }
	td:nth-of-type(8):before { content: "Total"; }

}

@media only screen and (min-width: 1024px) {
  .feed-post {
    max-width: 449px;
    height: 649px;
  }
  
/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Product Type"; }
	td:nth-of-type(2):before { content: "Product Metal Type"; }
	td:nth-of-type(3):before { content: "Metal Rate"; }
	td:nth-of-type(4):before { content: "Product Weight"; }
	td:nth-of-type(5):before { content: "Product Labour"; }
	td:nth-of-type(6):before { content: "Product Extra Charge"; }
	td:nth-of-type(7):before { content: "Discount"; }
	td:nth-of-type(8):before { content: "Total"; }
}
